<script>
export default {
  props: ["showModal", "isOpen"],
  methods: {
    scroll(id) {
      document.getElementById(id).scrollIntoView({
        behavior: "smooth",
      });
    },
  },
};
</script>

<!-- TODO: rewrite router links to scroll elements -->

<template>
  <!-- Header links -->
  <div
    :class="isOpen ? 'block' : 'hidden'"
    class="m-0 sm:ml-4 mt-5 sm:mt-3 sm:flex p-5 sm:p-0 justify-center items-center shadow-lg sm:shadow-none"
  >
    <!-- TODO: change routing of pages -->
    <div
      class="cursor-pointer font-general-medium block text-left text-lg font-medium text-primary-dark dark:text-ternary-light hover:text-[#9eddba] dark:hover:text-[#44a2cc] sm:mx-4 mb-2 sm:py-2"
      aria-label="Footure"
      @click="scroll('footure')"
    >
      Footure
    </div>
    <div
      class="cursor-pointer font-general-medium block text-left text-lg font-medium text-primary-dark dark:text-ternary-light hover:text-[#9eddba] dark:hover:text-[#44a2cc] sm:mx-4 mb-2 sm:py-2"
      aria-label="FootureWaasland"
      @click="scroll('waasland')"
    >
      Footure Waasland
    </div>
    <div
      class="cursor-pointer font-general-medium block text-left text-lg font-medium text-primary-dark dark:text-ternary-light hover:text-[#9eddba] dark:hover:text-[#44a2cc] sm:mx-4 mb-2 sm:py-2"
      aria-label="FootureHageland "
      @click="scroll('hageland ')"
    >
      Footure Hageland
    </div>
    <div
      class="cursor-pointer font-general-medium block text-left text-lg font-medium text-primary-dark dark:text-ternary-light hover:text-[#9eddba] dark:hover:text-[#44a2cc] sm:mx-4 mb-2 sm:py-2"
      aria-label="Slippers"
      @click="scroll('slippers')"
    >
      Slippers en sandalen
    </div>
    <!-- <router-link
      to="/about"
      class="font-general-medium block text-left text-lg font-medium text-primary-dark dark:text-ternary-light hover:text-[#9eddba] dark:hover:text-[#9eddba] sm:mx-4 mb-2 sm:py-2 border-t-2 pt-3 sm:pt-2 sm:border-t-0 border-primary-light dark:border-secondary-dark"
      aria-label="About Me"
      >Footure Waasland</router-link
    >
    <router-link
      to="/about"
      class="font-general-medium block text-left text-lg font-medium text-primary-dark dark:text-ternary-light hover:text-[#9eddba] dark:hover:text-[#9eddba] sm:mx-4 mb-2 sm:py-2 border-t-2 pt-3 sm:pt-2 sm:border-t-0 border-primary-light dark:border-secondary-dark"
      aria-label="About Me"
      >Footure Hageland </router-link
    > -->
    <!-- <router-link
      to="/contact"
      class="font-general-medium block text-left text-lg font-medium text-primary-dark dark:text-ternary-light hover:text-[#9eddba] dark:hover:text-[#9eddba] sm:mx-4 mb-2 sm:py-2 border-t-2 pt-3 sm:pt-2 sm:border-t-0 border-primary-light dark:border-secondary-dark"
      aria-label="Contact"
      >Contact</router-link
    > -->
    <div
      class="border-t-2 pt-3 sm:pt-0 sm:border-t-0 border-primary-light dark:border-secondary-dark"
    >
      <button
        class="bg-[#81d3a6] hover:bg-[#9eddba] dark:bg-[#44a2ccE6] dark:hover:bg-[#44a2cc] font-general-medium sm:hidden block text-left text-md font-medium text-white shadow-sm rounded-sm px-4 py-2 mt-2 duration-500"
        @click="showModal()"
        aria-label="Hire Me Button"
      >
        Maak een afspraak
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
