<script>
import feather from "feather-icons";
import FooterCopyright from "./FooterCopyright.vue";
export default {
  components: { FooterCopyright },
  data() {
    return {
      datapoints: [
        // {
        //   id: 1,
        //   name: "Algemeen",
        //   data: {
        //     email: "info@footure.be",
        //   },
        // },
        {
          id: 2,
          name: "Footure Waasland",
          data: {
            tel: "0472 70 29 88",
            email: "lisa@footure.be , info@footure.be",
            vestigingen: [
              "Sinaaidorp 17, 9112 Sinaai (Fysiodynamics)",
              "Nieuwe baan 106 B, 9111 Belsele (We Care)",
              "Fonteinstraat 79, 9140 Temse",
            ],
          },
        },
        {
          id: 3,
          name: "Footure hageland ",
          data: {
            tel: "0498 20 80 90",
            email: "joren@footure.be",
            vestigingen: ["Haksbergstraat 23, 3390 Tielt-Winge"],
          },
        },
        {
          id: 4,
          name: "Openingsuren",
          data: {
            uren: ["Weekdagen : 8u30 - 20u30", "Zaterdag : 8u30 – 15u"],
          },
        },
      ],
    };
  },
  mounted() {
    feather.replace();
  },
  updated() {
    feather.replace();
  },
};
</script>

<template>
  <div class="container mx-auto">
    <div
      class="pt-20 sm:pt-30 pb-8 mt-20 border-t-2 border-primary-light dark:border-secondary-dark"
    >
      <!-- Footer social links -->
      <div class="flex flex-col justify-center items-center mb-12 sm:mb-20">
        <p
          class="font-general-semibold text-3xl sm:text-4xl font-semibold text-primary-dark dark:text-primary-light mb-5"
        >
          Contact
        </p>
        <ul
          class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 mt-6 sm:gap-10"
        >
          <div v-for="data in datapoints" :key="data.id" target="__blank">
            <div
              :class="`text-primary-dark dark:text-primary-light`"
              class="mr-2"
            >
              <p
                class="font-general-semibold text-3xl sm:text-3xl font-semibold text-primary-dark dark:text-primary-light mb-5"
              >
                {{ data.name }}
              </p>
              <ul class="grid">
                <li class="mb-2">
                  <a
                    v-if="data.data.email != null"
                    class="font-general-semibold text-xl sm:text-xl font-semibold text-primary-dark dark:text-primary-light mb-5"
                  >
                    Email
                  </a>
                  <p
                    class="font-general-regular text-sm sm:text-md font-normal"
                  >
                    {{ data.data.email }}
                  </p>
                </li>
                <li class="mb-2">
                  <a
                    v-if="data.data.tel != null"
                    class="font-general-semibold text-xl sm:text-xl font-semibold text-primary-dark dark:text-primary-light mb-5"
                  >
                    Telefoon
                  </a>
                  <p
                    class="font-general-regular text-sm sm:text-md font-normal"
                  >
                    {{ data.data.tel }}
                  </p>
                </li>
                <a
                  v-if="data.data.vestigingen != null"
                  class="font-general-semibold text-xl sm:text-xl font-semibold text-primary-dark dark:text-primary-light"
                >
                  Vestigingen
                </a>
                <li v-for="item in data.data.vestigingen" :key="item">
                  <p
                    class="font-general-regular text-sm sm:text-md font-normal mb-2"
                  >
                    {{ item }}
                  </p>
                </li>

                <li v-for="item in data.data.uren" :key="item">
                  <p
                    class="font-general-regular text-sm sm:text-md font-normal mb-2"
                  >
                    {{ item }}
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </ul>
      </div>

      <!-- Footer copyright -->
      <FooterCopyright />
    </div>
  </div>
</template>

<style scoped></style>
