<template>
  <!-- Title Sections -->
  <section class="pt-10 sm:pt-14" :id="clickId">
    <!-- Projects grid title -->
    <div class="text-center">
      <p
        class="font-general-semibold text-2xl sm:text-5xl font-semibold mb-2 text-ternary-dark dark:text-ternary-light"
      >
        {{ title }}
      </p>
    </div>
  </section>

  <!-- Data section -->
  <section v-if="data != null" class="pt-10 sm:pt-14">
    <!-- Section options -->
    <section class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-2">
      <!-- for every practice in data.practices build a div -->
      <div v-for="practice in data.practices" :key="practice.id">
        <!-- Options -->
        <div
          class="p-6 rounded-lg h-full bg-[#9eddba] hover:bg-[#44a2cc] dark:hover:bg-[#44a2cc] cursor-pointer"
          @click="scroll(`section-${practice.id}`)"
        >
          <div class="mb-5 flex items-center justify-center">
            <img
              :src="practice.img"
              alt="Practice image"
              class="object-cover shadow-lg rounded-lg group-hover:opacity-75 h-48 w-96"
            />
          </div>

          <h3 class="text-ternary-dark text-lg font-bold mb-2">
            {{ practice.title }}
          </h3>

          <p class="text-sm leading-6 text-ternary-dark dark:text-ternary-dark">
            {{ practice.subtitle }}
          </p>
        </div>
      </div>
    </section>

    <!-- notes -->
    <!-- Note alternative -->
    <div
      v-if="data.altnote != null"
      class="mb-5 h-auto w-auto p-6 mt-8 rounded-lg bg-[#9eddba] border border-[#44a2cc]"
    >
      <div
        v-for="(paragraph, index) in data.altnote"
        :key="`practice-${clickId}-altnote-${index}`"
        class="text-sm leading-6 text-black-600 mb-4 first:text-2xl first:font-bold"
      >
        {{ paragraph }}
      </div>
    </div>
    <!-- important Note -->
    <div
      v-if="data.importantnote != null"
      class="mb-5 h-auto w-auto p-6 mt-8 rounded-lg bg-[#ddab9e] border border-[#44a2cc]"
    >
      <div
        v-for="(paragraph, index) in data.importantnote"
        :key="`practice-${clickId}-importantnote-${index}`"
        class="text-sm leading-6 text-black-600 mb-4 first:text-2xl first:font-bold"
      >
        {{ paragraph }}
      </div>
    </div>

    <!-- Section person data -->
    <section v-if="data.person != null" class="pt-10 sm:pt-14">
      <!-- Person data -->
      <div
        class="flex flex-col items-center"
        :class="
          data.person.row == 'row' ? ' md:flex-row' : 'md:flex-row-reverse'
        "
      >
        <div class="flex-1">
          <img :src="data.person.image" alt="Person image" />
        </div>

        <div class="flex-1">
          <h3
            class="text-ternary-dark dark:text-[#9eddba] text-lg font-bold mb-2"
          >
            {{ data.person.title }}
          </h3>
          <div
            v-for="(paragraph, index) in data.person.paragraphs"
            :key="`${data.person.name}-paragraph-${index}`"
            class="text-sm leading-6 text-ternary-dark dark:text-primary-light mb-4"
          >
            {{ paragraph }}
          </div>
        </div>
      </div>
      <!-- map -->
      <div class="pt-8">
        <l-map
          style="height: 50vh"
          class="pt-6"
          v-model="zoom"
          v-model:zoom="zoom"
          :center="[
            data.person.locationCenter.lat,
            data.person.locationCenter.lng,
          ]"
          :options="{
            scrollWheelZoom: false,
          }"
        >
          <l-tile-layer
            :url="getUrl()"
            class="dark:bg-primary-dark bg-primary-light"
            id="mapbox/dark-v10"
          ></l-tile-layer>

          <l-marker
            v-for="(location, index) in data.person.locations"
            :key="`${data.person.name}-location-marker-${index}`"
            :lat-lng="[location.lat, location.lng]"
          >
            <l-icon :icon-url="iconUrl" :icon-size="iconSize" />
          </l-marker>
        </l-map>
      </div>
    </section>

    <!-- Section Extra section options (grid with images)-->
    <section v-if="data != null" class="pt-10 sm:pt-14">
      <!-- Extra section options -->
      <div
        v-for="practice in data.practices"
        :key="`section-${practice.id}`"
        :id="`section-${practice.id}`"
      >
        <div class="flex flex-col md:flex-row-reverse items-center">
          <div v-if="practice.section != null" class="flex-1">
            <h1
              class="text-ternary-dark dark:text-[#9eddba] text-4xl font-bold mb-4 mt-10"
            >
              {{ practice.section.title }}
            </h1>
            <div
              v-for="(paragraph, index) in practice.section.description"
              :key="`practice-${practice.id}-paragraph-${index}`"
              class="text-sm leading-6 text-ternary-dark dark:text-primary-light mb-4"
            >
              {{ paragraph }}
            </div>

            <!-- Image -->
            <img
              v-if="practice.section.image !== undefined"
              :src="practice.section.image"
              alt="Extra image"
            />

            <!-- Step -->
            <div
              v-if="practice.section.steps !== undefined"
              class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-2"
            >
              <div v-for="step in practice.section.steps" :key="step.id">
                <div class="p-6 rounded-lg h-full bg-[#9eddba]">
                  <div class="relative mb-5">
                    <!-- add absolute positioned number above image with the value practice.id that is  inside a circle -->
                    <div
                      class="flex justify-center items-center w-1/5 h-1/5 absolute text-2xl text-center text-ternary-dark dark:text-ternary-light rounded-full bg-[#44a2cc]"
                      style="transform: translate(-50%, -50%)"
                    >
                      <a>
                        {{ step.id }}
                      </a>
                    </div>

                    <img
                      :src="step.img"
                      alt="step image"
                      class="object-cover shadow-lg rounded-lg group-hover:opacity-75 h-48 w-96"
                    />
                  </div>

                  <h3 class="text-ternary-dark text-lg font-bold mb-2">
                    {{ step.title }}
                  </h3>

                  <p
                    class="text-sm leading-6 text-ternary-dark dark:text-primary-light"
                  >
                    {{ step.subtitle }}
                  </p>
                </div>
              </div>
            </div>
            <!-- TODO add canvas met voorbeeld afbeeldingen roterend -->

            <!-- Options -->
            <div
              v-if="practice.section.options !== undefined"
              class="mb-5 h-auto w-auto p-6 mt-1 rounded-lg border border-[#44a2cc]"
            >
              <div
                v-for="option in practice.section.options"
                :key="`option-${option.id}`"
                :id="`option-${option.id}`"
              >
                <h3
                  class="text-ternary-dark dark:text-[#9eddba] text-lg font-bold mb-2"
                >
                  {{ option.title }}
                </h3>
                <div
                  class="text-sm leading-6 text-ternary-dark dark:text-primary-light mb-4"
                >
                  {{ option.description }}
                </div>
              </div>
            </div>
            <!-- Note -->
            <div
              v-if="practice.section.note !== undefined"
              class="mb-5 h-auto w-auto p-6 mt-8 rounded-lg bg-[#9eddba] border border-[#44a2cc]"
            >
              <div
                v-for="(paragraph, index) in practice.section.note"
                :key="`practice-${practice.id}-note-${index}`"
                class="text-sm leading-6 text-black-600 mb-4"
              >
                {{ paragraph }}
              </div>
            </div>
            <!-- Note alternative -->
            <div
              v-if="practice.section.altnote !== undefined"
              class="mb-5 h-auto w-auto p-6 mt-8 rounded-lg bg-[#9eddba] border border-[#44a2cc]"
            >
              <div
                v-for="(paragraph, index) in practice.section.altnote"
                :key="`practice-${practice.id}-altnote-${index}`"
                class="text-sm leading-6 text-black-600 mb-4 first:text-2xl first:font-bold"
              >
                {{ paragraph }}
              </div>
            </div>
            <!-- important Note -->
            <div
              v-if="practice.section.importantnote !== undefined"
              class="mb-5 h-auto w-auto p-6 mt-8 rounded-lg bg-[#ddab9e] border border-[#44a2cc]"
            >
              <div
                v-for="(paragraph, index) in practice.section.importantnote"
                :key="`practice-${practice.id}-importantnote-${index}`"
                class="text-sm leading-6 text-black-600 mb-4 first:text-2xl first:font-bold"
              >
                {{ paragraph }}
              </div>
            </div>
            <!-- Lists -->
            <div
              v-if="practice.section.lists !== undefined"
              class="mb-5 h-auto w-auto p-6 mt-1 flex flex-col md:flex-row justify-between items-center"
            >
              <div
                v-for="list in practice.section.lists"
                :key="`list-${list.id}`"
                :id="`list-${list.id}`"
              >
                <h3
                  class="text-left text-ternary-dark dark:text-[#9eddba] text-lg font-bold mb-2"
                >
                  {{ list.header }}
                </h3>
                <div
                  class="text-sm leading-6 text-ternary-dark dark:text-primary-light mb-4"
                >
                  <ul
                    v-for="(point, index) in list.data"
                    :key="`list-${list.id}-point-${index}`"
                    :id="`list-${list.id}-point-${index}`"
                    class="list-disc ml-4 text-left"
                  >
                    <li>{{ point }}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import "leaflet/dist/leaflet.css";
import { LMap, LIcon, LTileLayer, LMarker } from "@vue-leaflet/vue-leaflet";

export default {
  components: {
    LMap,
    LIcon,
    LTileLayer,
    LMarker,
  },
  props: ["title", "data", "clickId"],
  methods: {
    scroll(id) {
      document.getElementById(id).scrollIntoView({
        behavior: "smooth",
      });
    },
    getUrl() {
      if (localStorage.getItem("theme") == "dark") {
        return "https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png";
        // dark mode
      } else {
        // light mode
        return "https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png";
      }
    },
  },
  data() {
    return {
      zoom: 10,
      iconWidth: 50,
      iconHeight: 50,
    };
  },

  computed: {
    iconUrl() {
      return require("@/assets/images/logo.svg");
    },
    iconSize() {
      return [this.iconWidth, this.iconHeight];
    },
  },
};
</script>

<style scoped></style>
