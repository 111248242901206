<template>
  <!-- Title Sections -->
  <section class="pt-10 sm:pt-14" :id="clickId">
    <!-- Projects grid title -->
    <div class="text-center">
      <p
        class="font-general-semibold text-2xl sm:text-5xl font-semibold mb-2 text-ternary-dark dark:text-ternary-light"
      >
        {{ content.title }}
      </p>
    </div>
  </section>

  <section v-if="content.data != null" class="pt-10 sm:pt-14">
    <div
      v-for="section in content.data"
      :key="`${content.name}-section-${section.id}`"
      :id="`${content.name}-section-${section.id}`"
    >
      <!-- POINTS -->
      <div
        v-if="section.points != null"
        class="grid grid-cols-1 lg:grid-cols-3 md:flex-row items-center"
      >
        <div
          v-for="(point, index) in section.points"
          :key="`${content.name}-point-${index}`"
          class="h-full text-left text-lg leading-6 text-ternary-dark dark:text-primary-light mb-4 p-4"
        >
          <a
            v-for="(part, indexPoint) in point"
            :key="`${content.name}-part-${index}-point-${indexPoint}`"
            class="text-lg leading-6 mb-4"
            :class="
              part.toUpperCase() == part
                ? 'bg-[#9eddba] text-primary-dark'
                : 'text-ternary-dark dark:text-primary-light'
            "
          >
            {{ part }}
          </a>
        </div>
      </div>

      <!-- Steps -->
      <div
        v-if="section.steps !== undefined"
        class="grid grid-cols-1 lg:grid-cols-3 gap-2"
      >
        <div v-for="step in section.steps" :key="step.id">
          <div class="p-6 rounded-lg h-full bg-[#9eddba]">
            <div class="relative mb-5">
              <!-- add absolute positioned number above image with the value practice.id that is  inside a circle -->
              <div
                class="flex justify-center items-center w-1/5 h-1/5 absolute text-2xl text-center text-[#44a2cc]"
                style="transform: translate(-50%, -50%)"
              >
                <a>
                  {{ step.id }}
                </a>
              </div>
            </div>

            <h3 class="text-ternary-dark text-lg font-bold mb-2">
              {{ step.title }}
            </h3>

            <p class="text-sm leading-6 text-ternary-dark">
              {{ step.description }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: ["content", "clickId"],
};
</script>

<style scoped></style>
