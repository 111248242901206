<script>
import SandalenSlippers from "./SandalenSlippers.vue";
import { Carousel, Slide, Navigation } from "vue3-carousel";

import "vue3-carousel/dist/carousel.css";

export default {
  components: {
    SandalenSlippers,
    Carousel,
    Slide,
    Navigation,
  },
  data: () => {
    return {
      content: {
        title: "Sandalen & Slippers",
        name: "sandalen-slippers",
        clickId: "slippers",
        data: [
          {
            id: 1,
            tile: "Waarom sandalen of slippers op maat",
            points: [
              [
                "Ervaar het effect van uw podologische zooltherapie elk moment van de dag met dank aan ons ",
                "UNIEK MAATWERK",
                " in slechts 3 stappen.",
              ],
              [
                "Ideaal om tijdens warme zomerdagen of binnenshuis een ",
                "AANGENAAM STAPCOMFORT",
                " te ervaren.",
              ],
              [
                "Onze slippers en sandalen zijn bovendien volledig ",
                "PERSONALISEERBAAR",
                " naar eigen smaak.",
              ],
            ],
          },
          {
            id: 2,
            title: "Therapeutisch schoeisel in 3 stappen",
            subtitle:
              "Wij stellen samen met u uw persoonlijk zoolconcept samen. Allerlei elementen zoals een uitsparing, een zachtere regio, extra voorvoetsteun, een hiellift... kunnen aan de zool toegevoegd worden. Bepaalde aandoeningen kunnen via deze aanvullende zooltherapie optimaal gecontroleerd worden. Voorvoetsklachten, hielspoor, Achillespeesklachten, een doortrapte hiel ... zijn voorbeelden hiervan. Via dit therapeutisch schoeisel kan u het effect van uw podologische zooltherapie op élk moment van de dag ervaren.",
            steps: [
              {
                id: 1,
                title: "Onderzoek",
                description:
                  "Er wordt een biomechanisch onderzoek, een drukmeting en een ganganalyse uitgevoerd. Aan de hand van deze bevindingen wordt uw zoolconcept bepaald. ",
              },
              {
                id: 2,
                title: "Uw 3D-voetscan",
                description:
                  "Er wordt een 3D-scan afgenomen vanuit een neutrale positie. Dit zorgt voor enige sturing en optimaal pascomfort. ",
              },
              {
                id: 3,
                title: "Personalisatie",
                description:
                  "Er is een ruime keuze uit allerlei modellen en modieuze prints. Zowel het bovenwerk, de toplaag, de kleur van het voetbed en de kleur van de loopzool kan u bepalen",
              },
            ],
          },
        ],
        images: [
          //   {
          //     id: 1,
          //     img: require("@/assets/images/slippers/slipper_1.jpg"),
          //     alt: "Sandalen & Slippers",
          //   },
          //   {
          //     id: 2,
          //     img: require("@/assets/images/slippers/slipper_2.jpg"),
          //     alt: "Sandalen & Slippers",
          //   },
          {
            id: 3,
            img: require("@/assets/images/slippers/slipper_3.jpg"),
            alt: "Sandalen & Slippers",
          },
          //   {
          //     id: 4,
          //     img: require("@/assets/images/slippers/slipper_4.jpg"),
          //     alt: "Sandalen & Slippers",
          //   },
          {
            id: 5,
            img: require("@/assets/images/slippers/slipper_5.jpg"),
            alt: "Sandalen & Slippers",
          },
          {
            id: 6,
            img: require("@/assets/images/slippers/slipper_6.jpg"),
            alt: "Sandalen & Slippers",
          },
          {
            id: 7,
            img: require("@/assets/images/slippers/slipper_7.jpg"),
            alt: "Sandalen & Slippers",
          },
          {
            id: 8,
            img: require("@/assets/images/slippers/slipper_8.jpg"),
            alt: "Sandalen & Slippers",
          },
          {
            id: 9,
            img: require("@/assets/images/slippers/slipper_9.jpg"),
            alt: "Sandalen & Slippers",
          },
        ],
      },
    };
  },
};
</script>

<template>
  <div class="container mx-auto mt-10 sm:mt-20">
    <SandalenSlippers :content="content" :clickId="content.clickId" />
    <!-- TODO Add canvas met voorbeeldschoenen (ga door afbeeldingen) -->
    <Carousel :autoplay="4000" :wrap-around="true" class="mt-8">
      <Slide
        v-for="image in content.images"
        :key="image.id"
        class="w-full h-full"
      >
        <div class="carousel__item flex justify-center h-96 w-full">
          <!-- insert image -->
          <img
            :src="image.img"
            :alt="image.alt"
            class="object-cover shadow-lg rounded-lg"
          />
        </div>
      </Slide>

      <template #addons>
        <Navigation />
      </template>
    </Carousel>
  </div>
</template>

<style>
.carousel__item {
  min-height: 200px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.1);
  font-size: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel__slide {
  padding: 10px;
}

.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  border: 5px solid #9eddba;
  background-color: #44a2cc;
}
.carousel__icon {
  color: #9eddba;
}
</style>
