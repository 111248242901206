<template>
    <div v-if="isVisible" class="modal" :style="{ display: isVisible ? 'block' : 'none' }">
      <div class="modal-content">
        <span class="close" @click="closeModal">&times;</span>
        <!-- <p>{{ message }}</p> -->
        <div class="message" v-html="formattedMessage"></div>

      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: ['isVisible', 'message'],
    methods: {
      closeModal() {
        this.$emit('update:isVisible', false);
      }
    },
    computed: {
    formattedMessage() {
      return this.message.replace(/\n/g, '<br>');
    }
  }
  }
  </script>
  
  <style>
  .modal {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.4);
  }
  
  .modal-content {
    background-color: #fefefe;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
  }
  
  .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  
  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
  </style>