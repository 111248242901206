<script>
import FootureLocation from "./FootureLocation.vue";

export default {
  components: {
    FootureLocation,
  },
  data: () => {
    return {
      locations: [
        {
          id: 1,
          clickId: "footure",
          title: "Footure",
          data: {
            // person: {
            //   title: "Uw podoloog? Lisa Martens",
            //   name: "Lisa Martens",
            //   paragraphs: [
            //     "Ik ben Lisa Martens, erkende podoloog. In juni 2015 behaalde ik mijn Bachelordiploma in de Podologie aan de Artevelde Hogeschool te Gent.",
            //     "Voortvloeiend vanuit m’n passie en interesse in de sportwereld, specialiseer ik me in het maken van podologische (sport)zolen. Natuurlijk kunnen ook niet-sportievelingen bij mij terecht.",
            //     "Doordat ik werkzaam ben in vier multidisciplinaire centra sta ik in nauw verband met verschillende (manuele)kinesitherapeuten & osteopaten. Teamoverleg en onderlinge doorverwijzing maken het mogelijk om patiënten veel ruimer te behandelen. Tevens ben ik als podoloog werkzaam in het AZL, waar ik diabetespatiënten screen.",
            //     "Om up-to-date te blijven van nieuwste ontwikkelingen in de podologie, woon ik regelmatig binnen-en buitenlandse congressen bij.",
            //   ],
            //   image: require("@/assets/images/footure_waasland/header_clear.png"),
            // },
            practices: [
              {
                id: 1,
                title: "Podologie",
                img: require("@/assets/images/footure_waasland/top_section_1_close.jpg"),
                subtitle:
                  "Wat is podologie? Ik geef u graag een woordje uitleg!",
                section: {
                  title: "Wat is podologie?",
                  description: [
                    "Een podoloog behandelt mensen met klachten ter hoogte van het onderste lidmaat. Een foutieve voetenstand kan de onderliggende oorzaak zijn van knie-, heup- en/of rugproblematiek.",
                    "Iedereen is welkom, ongeacht de leeftijd of het activiteitsniveau. Zowel kinderen, (jong-)volwassenen als ouderen komen in de praktijk langs. Niet-sportievelingen, maar ook sporters kunnen beroep doen op onze podologische zorg.",
                    "We behandelen regelmatig patiënten met pijn in de voorvoet, een pijnlijke Hallux Valgus, een peesplaatontsteking, een scheenbeenvliesontsteking, een achillespeesontsteking, een lopersknie, enz...",
                    "Uw klachten zullen oorzakelijk behandeld worden a.d.h.v. een uitgebreid onderzoek. Na een evaluatie van de onderzoeksresultaten, wordt een adequate behandeling opgestart",
                  ],
                  note: [
                    "Aangezien podologie een jong beroep is, voorzien (nog) niet alle mutualiteiten een tegemoetkoming in de zooltherapie.",
                    "- Helan voorziet een jaarlijkse terugbetaling van € 50.",
                    "- De Liberale Mutualiteit voorziet een tweejaarlijkse terugbetaling van € 30.",
                    "- Vanaf 2023 voorziet het Vlaams & Neutraal Ziekenfonds ook een jaarlijkse terugbetaling van € 50.",
                    "U hebt geen voorschrift nodig om van deze terugbetaling te genieten. Zo  hoeft u niet eerst een specialist te consulteren, alvorens u een afspraak wenst te maken bij uw podoloog.",
                  ],
                },
              },
              {
                id: 2,
                title: "Onderzoek",
                img: require("@/assets/images/footure_waasland/top_section_2.jpg"),
                subtitle:
                  "Voor we de behandeling kunnen starten doen we een uitvoerig onderzoek. Bekijk hoe het onderzoek verloopt.",
                section: {
                  title: "Onderzoek",
                  steps: [
                    {
                      id: 1,
                      img: require("@/assets/images/footure_waasland/step_1.jpg"),
                      title: "Anamnesegesprek",
                      description:
                        "Hierbij schets ik je activiteitsniveau, werkomgeving, medische voorgeschiedenis en pijnbeleving.",
                    },
                    {
                      id: 2,
                      img: require("@/assets/images/footure_waasland/step_2.jpg"),
                      title: "Klinisch en biomechanisch onderzoek",
                      description:
                        "Er wordt een beeld gevormd van de algemene mobiliteit van de onderste ledematen. Tevens wordt de Core stability, een belangrijk topic in de blessurepreventie, geëvalueerd.",
                    },
                    {
                      id: 3,
                      img: require("@/assets/images/footure_waasland/step_3.jpg"),
                      title: "Gang- en/of loopanalyse",
                      description:
                        "Uw gang- en/of looppatroon wordt in verschillende fasen geanalyseerd. Aan het einde van het onderzoek worden de beelden samen met u geëvalueerd. Deze analyse wordt tevens gefilmd. Dit maakt een correcte opvolging op lange termijn mogelijk.",
                    },
                    {
                      id: 4,
                      img: require("@/assets/images/footure_waasland/step_4.jpg"),
                      title: "Plantaire drukmeting",
                      description:
                        "Verschillende (piek)drukken worden gemeten tijdens het stappen en/of het lopen. Relevante resultaten van deze drukmeting worden geïntegreerd in uw zoolconcept. Dit is bijzonder interessant bij diabetici en/of reumapatiënten om pijnlijke drukpunten te ontlasten. Vervolgens wordt een passende behandeling voorgesteld en opgestart.",
                    },
                  ],
                },
              },
              {
                id: 3,
                title: "Behandeling",
                img: require("@/assets/images/footure_waasland/top_section_3.jpg"),
                subtitle:
                  "Er bestaan verschillende podologische behandelingen. Klik hier om te kijken welke therapie het best bij uw klacht past.",
                section: {
                  title: "Behandeling",
                  subtitle:
                    "Naargelang de mogelijkheden en slaagkansen kan er geopteerd worden voor onderstaande behandelingswijzen :",
                  options: [
                    {
                      id: 1,
                      title: "Educatie/advies",
                      description:
                        "Er wordt advies gegeven omtrent stretchoefeningen, (sport)schoenkeuze, kniptechniek,... Bij bepaalde casussen is een doorverwijzing of samenwerking met andere disciplines belangrijk om uw klacht(en) oorzakelijk te kunnen behandelen.",
                    },
                    {
                      id: 2,
                      title: "Zooltherapie",
                      description:
                        "Via podologische zolen zal men uw gangpatroon aanpassen om vervolgens uw beweging te optimaliseren.",
                    },
                    {
                      id: 3,
                      title: "Silicone-orthesen",
                      description:
                        "Een silicone-orthese kan een afwijkende tenenstand (gedeeltelijk) corrigeren. Dit is afhankelijk van de mobiliteit van de gewrichten. Naargelang de materiaalkeuze kan men corrigerend of eerder ondersteunend gaan werken. Deze therapie wordt regelmatig toegepast bij o.a. recidiverende likdoorns tussen   de tenen of ter preventie van drukwonden tussen of bovenop klauw- en/of hamertenen,...",
                    },
                  ],
                  //   TODO: add to new paragraph => Consultatie
                  //   altnote: [
                  //     "Wat neemt u mee op consultatie?",
                  //     "- Short",
                  //     "- Indien u reeds steunzolen draagt, gelieve uw huidige steunzolen mee te nemen.",
                  //     "- Indien u medisch beeldmateriaal ter beschikking hebt, gelieve dit mee te nemen.",
                  //     "- Indien u frequent sport, gelieve uw sportschoenen mee te nemen voor een adequate loopanalyse.",
                  //   ],
                  //   importantnote: [
                  //     "Wat te doen bij annulatie?", //TODO: make bold
                  //     "Gelieve zo snel mogelijk te verwittigen wanneer u uw afspraak niet kan nakomen.",
                  //     "Uw afspraak zal naar een nieuwe datum verplaatst worden.",
                  //     "Zo geeft u een andere patiënt de kans om het vrijgekomen consult in te nemen.",
                  //     "Indien u niet verwittigd of uw consultatie minder van 24u vóór de geplande afspraak annuleert, zijn wij genoodzaakt een vergoeding t.w.v. € 25 aan te rekenen.",
                  //   ],
                },
              },
              {
                id: 4,
                title: "Zolen",
                img: require("@/assets/images/footure_waasland/top_section_4.jpg"),
                subtitle:
                  "Zolen op maat worden gemaakt na het nemen van een 3D scan. U wordt gratis opgevolgd.",
                section: {
                  title: "Zolen",
                  lists: [
                    {
                      id: 1,
                      header: "Waarom een podologische zool ?",
                      data: [
                        "Gebaseerd op een 3D-scan van jouw voet",
                        "Mooie, unieke pasvorm",
                        "Statische én dynamische analyse",
                        "Correctie naar een optimaal bewegingspatroon",
                      ],
                    },
                    {
                      id: 2,
                      header: "Ontwerp van uw zool",
                      data: [
                        "Grondige analyse",
                        "3D-scan van beide voeten in gecorrigeerde stand",
                        "Zoolconcept op basis van voorgaand onderzoek",
                        "Uw zolen worden uitgefreesd en afgewerkt in eigen atelier",
                      ],
                    },
                  ],
                },
              },
            ],
          },
        },
        {
          id: 4,
          title: "Consultatie",
          clickId: "consultatie",
          data: {
            title: "Consultatie",
            subtitle:
              "Naargelang de mogelijkheden en slaagkansen kan er geopteerd worden voor onderstaande behandelingswijzen :",
            //   TODO: add to new paragraph => Consultatie
            altnote: [
              "Wat neemt u mee op consultatie?",
              "- Short",
              "- Indien u reeds steunzolen draagt, gelieve uw huidige steunzolen mee te nemen.",
              "- Indien u medisch beeldmateriaal ter beschikking hebt, gelieve dit mee te nemen.",
              "- Indien u frequent sport, gelieve uw sportschoenen mee te nemen voor een adequate loopanalyse.",
            ],
            importantnote: [
              "Wat te doen bij annulatie?", //TODO: make bold
              "Gelieve zo snel mogelijk te verwittigen wanneer u uw afspraak niet kan nakomen.",
              "Uw afspraak zal naar een nieuwe datum verplaatst worden.",
              "Zo geeft u een andere patiënt de kans om het vrijgekomen consult in te nemen.",
              "Indien u niet verwittigd of uw consultatie minder van 24u vóór de geplande afspraak annuleert, zijn wij genoodzaakt een vergoeding t.w.v. € 25 aan te rekenen.",
            ],
          },
        },
        {
          id: 2,
          clickId: "waasland",
          title: "Footure Waasland",
          data: {
            person: {
              title: "Uw podoloog? Lisa Martens",
              name: "Lisa Martens",
              row: "row-reverse",

              paragraphs: [
                "Ik ben Lisa Martens, erkende podoloog. In juni 2015 behaalde ik mijn Bachelordiploma in de Podologie aan de Artevelde Hogeschool te Gent.",
                "Ik breng mijn vrije tijd graag actief door. Zo ga ik regelmatig wandelen, fietsen en lopen. Regelmatig dienen patiënten net deze activiteiten te staken omwille van klachten aan het onderste lidmaat.",
                "Via een podologische behandeling tracht ik deze mensen terug pijnvrij hun    werk of sportieve hobby's te laten beoefenen.",
                "Doordat ik in twee multidisciplinaire centra werk, sta ik in contact met enkele manuele kinesitherapeuten en osteopaten. Ik werk ook nauw samen met een collega-podoloog.",
                "Teamoverleg en onderlinge doorverwijzing maken het mogelijk om m'n patiënten ruimer te behandelen. Ik ben ook werkzaam in het Vitaz te Lokeren, waar ik wekelijks diabetici screen. Om up-to-date te blijven van de nieuwste ontwikkelingen in de podologie, woon ik regelmatig congressen bij. ",
              ],
              image: require("@/assets/images/footure_waasland/header_clear.png"),
              locationCenter: {
                lat: 51.13586,
                lng: 4.1438925,
              },
              locations: [
                {
                  lat: "51.157140",
                  lng: "4.039190",
                },
                {
                  lat: "51.145620",
                  lng: "4.107200",
                },
                {
                  lat: "51.1256315",
                  lng: "4.2282887",
                },
              ],
            },
          },
        },
        {
          id: 3,
          clickId: "hageland ",
          title: "Footure Hageland ",
          data: {
            person: {
              title: "Uw podoloog? Joren Verheyden",
              name: "Joren Verheyden",
              row: "row",
              paragraphs: [
                "Joren Verheyden en Lien Herbots zijn beiden afgestudeerd als bachelor in de Podologie.",
                "",
              ],
              image: require("@/assets/images/verheyden_foto.jpg"),
              locationCenter: {
                lat: 50.9767629,
                lng: 4.8078662,
              },
              locations: [
                {
                  lat: "50.951600",
                  lng: "4.907830",
                },
              ],
            },
          },
        },
      ],
    };
  },
};
</script>

<template>
  <div class="container mx-auto mt-10 sm:mt-20">
    <FootureLocation
      v-for="location in locations"
      :key="location.id"
      :title="location.title"
      :data="location.data"
      :clickId="location.clickId"
    />
  </div>
</template>
