<script>
import AppBanner from "@/components/shared/AppBanner";
import FootureContent from "../components/pages/FootureContent.vue";
import SandalenSlippersContent from "../components/pages/SandalenSlippersContent.vue";
import MyModal from "@/components/reusable/AlertModal";

export default {
  name: "Home",
  components: {
    AppBanner,
    FootureContent,
    SandalenSlippersContent,
    MyModal,
  },
  data() {
    return {
      isModalVisible: true, // Set this to true to show the modal on load
    };
  },
  methods: {
    showModal() {
      this.isModalVisible = true;
    }
  }
};
</script>

<template>
  <div class="container mx-auto">
    <!-- Banner -->
    <MyModal :isVisible="isModalVisible" @update:isVisible="isModalVisible = $event" :message="'Beste patiënt,\n momenteel ben ik in zwangerschapsverlof. Er is vervanging voorzien door een collega podoloog.\n Bij wijzigingen van lopende afspraken en bij dringende gevallen, kan u ons per voorkeur via e-mail contacteren.\n Uw e-mail, bericht of oproep zal behandeld worden, maar enige vertraging is mogelijk. \n Alvast bedankt voor uw begrip.\n\n Groeten, \nLisa Martens'"/>
    <AppBanner class="mb-5 sm:mb-8" />

    <FootureContent />

    <SandalenSlippersContent />
  </div>
</template>

<style scoped></style>
